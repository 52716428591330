import React from "react";
import {Badge} from "reactstrap";
import PropTypes from "prop-types";
import classnames from "classnames";
import ConfigDisplay from "./ConfigDisplay";
import ConfigEdit from "./ConfigEdit";
import {titleCaseWord} from "../../../utils/string";
import usePermission from "../../../Hooks/usePermission";

/*
 * Displays a grouping of configuration settings for a purpose
 * such as an integration or SIP configuration.
 *
 * The settings are defined by a definition object (in the case of integrations,
 * this object is retrieved from the database).
 *
 * This component toggles between display view and edit view (view mode controlled by parent).
 *
 * Index is provided because the parent might map multiple Configurations
 * (for example, delivery destinations has multiple integration configs)
 */
const Configuration = (props) => {
  const allowEditIntegration = usePermission('integrations.edit');

  const getConfigLabel = (name) => {
    const conf = props.definitions.find((c) => c.name === name);

    if (conf) {
      let label = conf.label
      if (conf.status !== "active") {
        //label += ` [${conf.status.toUpperCase()}]`;
        label = <span>{label}<Badge color="warning" className="ms-1">{titleCaseWord(conf.status)}</Badge></span>
      }
      return label;
    }
    return name;
  };

  return (
    <div className={classnames(['configuration', 'integration-container', 'flex-fill', props.type], { display: !props.editMode })}>
      {props.allowEdit && allowEditIntegration && props.editMode ? (
        <ConfigEdit {...props} />
      ) : (
        <ConfigDisplay {...props} label={getConfigLabel(props.config.name)} allowEditIntegration={allowEditIntegration} />
      )}
    </div>
  );
};

Configuration.propTypes = {
  index: PropTypes.number, // which configuration in collection we are displaying/exiting
  type: PropTypes.string,
  allowControls: PropTypes.bool,
  editMode: PropTypes.bool,
  isNew: PropTypes.bool,
  showServiceChooser: PropTypes.bool,
  definitions: PropTypes.array.isRequired, // configuration definitions for all available integrations of this type
  config: PropTypes.object, // current values for the settings, object with 'name' and 'settings'
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  errors: PropTypes.object,
  willDelete: PropTypes.bool,
  setWillDelete: PropTypes.func,
  onApply: PropTypes.func,
  setEdit: PropTypes.func,
  allowEdit: PropTypes.bool
};

Configuration.defaultProps = {
  errors: {},
  values: {},
  index: 0,
  editMode: false,
  isNew: false,
  allowControls: true,
  showServiceChooser: true,
};

export default Configuration;
