import React, { useEffect, useState } from 'react';
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { TextSearch, StackedFilter } from '../../../Components/Table/Filter';
import { StatusChoices, ProductChoices } from '../../../Components/Table/FilterChoices';
import FilterSummary from '../../../Components/Table/FilterSummary';
import useApiBatch from '../../../Hooks/useApiBatch';
import RightDateAndControls from '../../../Components/Table/RightDateAndControls';
import {useFilters} from "../../../Hooks/useFilters";

const Filters = ({
  filters, onChange, onRefresh, loading = false,
}) => {
  const batcher = useApiBatch();
  const [choices, setChoices] = useState({});
  const {onChangeFilter, reset, clearSearch} = useFilters(filters, onChange)

  // load choices
  useEffect(() => {
    const getChoices = async () => {
      const batches = await batcher.fetch([
        { endpoint: 'vendors', action: 'list' },
        { endpoint: 'verticals', action: 'list' },
        { endpoint: 'channels', action: 'list', params: { options: 1 } },
      ]);
      if (batches && batches.length) {
        const [vendors, verticals, channels] = batches;
        setChoices({
          status: StatusChoices,
          product: ProductChoices,
          vendors: vendors.map((item) => ({ value: item.id.toString(), label: item.name })),
          vertical: verticals.map((item) => ({ value: item.id, label: item.display_name })),
          channel: channels.map((item) => ({ value: item.id, label: item.name })),

          // vendor: vendors.map( item => { return {value:item.id.toString(), label:item.name}}),
        });
      }
    };

    if (Object.keys(choices).length === 0) getChoices();
  }, []);


  return (
    <div className="d-flex  justify-content-between position-relative">
      <div className={classnames(['loading-overlay', { show: loading }])} />
      <div className="status-filters d-flex">

        <div className="boxed-filter search-filter me-3">
          <TextSearch
            value={filters.search || ''}
            placeholder="Search by source name or token"
            onUpdate={(val) => onChangeFilter('search', val)}
            onClear={clearSearch}
            delay={500}
          />
        </div>

        {/* Use of  inNavbar={true} disables popper and allows application of '.dropDown' animation class to menu */}
        <UncontrolledDropdown id="report-group-menu" className="ms-3" inNavbar>
          {/* "nav" makes toggle <a> rather than <button> */}
          <DropdownToggle nav caret className="boxed-filter filter-group-dropdown">
            <FaIcon icon="filter" size="lg" className="fa-icons" />
            <span className="title d-block">Applied Filters</span>
            <small>
              <FilterSummary choices={choices} filters={filters} filterKeys={['status', 'product', 'vendors', 'vertical_id', 'channel_id']} />
            </small>
          </DropdownToggle>
          <DropdownMenu className="select-filters-dropdown animate dropDown">

            <div className="modal-body">
              <StackedFilter
                placeholder="Any Vertical"
                label="Vertical"
                param="vertical_id"
                options={choices.vertical || []}
                value={filters.vertical_id || ''}
                onChange={onChangeFilter}
                isMulti={false}
              />

              <StackedFilter
                placeholder="Any product"
                label="Product"
                param="product"
                options={choices.product || []}
                value={filters.product || []}
                onChange={onChangeFilter}
                isMulti
              />

              <StackedFilter
                placeholder="Any channel"
                label="Channel"
                param="channel_id"
                options={choices.channel || []}
                value={filters.channel_id || []}
                onChange={onChangeFilter}
                isMulti={false}
              />

              <StackedFilter
                placeholder="Any status"
                label="Status"
                param="status"
                options={choices.status || []}
                value={filters.status || []}
                onChange={onChangeFilter}
                isMulti
              />

            </div>

          </DropdownMenu>
        </UncontrolledDropdown>

      </div>

      <RightDateAndControls
        onChangeDate={null}
        onRefresh={onRefresh}
        onReset={reset}
      />
    </div>

  );
};

Filters.propTypes = {
  filters: PropTypes.object,
  onChange: PropTypes.func,
  onRefresh: PropTypes.func,
  accountId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  loading: PropTypes.bool,
};

export default Filters;
