import React, {useMemo} from "react";
import {Link} from 'react-router-dom';
import EntityId from "../../../Components/Table/EntityId";
import {CopyButton, RelativeTime} from "@thedmsgroup/mastodon-ui-components";
import {FontAwesomeIcon as FaIcon} from "@fortawesome/react-fontawesome";
import StatusToggler from "../../../Components/Form/StatusToggler";
import {Button, UncontrolledTooltip} from "reactstrap";
import PagedTable from "../../../Components/Table/PagedTable";


export const SourceTokenTable = ({
                                   data,
                                   onEdit,
                                   onArchive,
                                   loading,
                                   noDataMessage,
                                   showSource=true,
                                   showVendor=true,
                                   ...tableProps
}) => {

  const columns = useMemo(() => {

    const optionalCols = [];
    // Optional columns
    // From vendors list, we are just showing tokens for one vendor, so don't show vendor column.
    // Similar if showing this table from the sources list
    if (showSource) {
      optionalCols.push({
        Header: 'Source',
        className: 'source-cell',
        accessor: 'source',
        defaultCanSort: true,
        Cell: ({value}) => (
          <><EntityId id={value.id} title="Source ID"/> {value.name}</>
        ),
      })
    }

    if (showVendor) {
      optionalCols.push({
        Header: 'Vendor',
        className: 'vendor-cell',
        accessor: 'vendor',
        defaultCanSort: true,
        Cell: ({value}) => (
          <><EntityId id={value.id} title="Vendor ID"/> {value.name}</>
        ),
      })
    }
    return [
      {
      Header: 'Token',
      id: 'token',
      defaultCanSort: false,
      minWidth: 130,
      className: 'token-cell',
      Cell: ({row}) => (
        <TokenCell tokenId={row.original.id} label={row.original.label} description={row.original.description} token={row.original.token} />
      ),
    },
      ...optionalCols,
/*      {
        Header: 'Token',
        className: 'token-name-cell',
        id: 'token-name',
        defaultCanSort: true,
        Cell: ({row}) => <NameCell label={row.original.label} description={row.original.description}/>
      },*/


      {
        Header: 'Status',
        accessor: 'status',
        defaultCanSort: true,
        width: 90,
        className: 'status-cell',
        disabledGlobalFilter: true,
        Cell: (c) => (
          <StatusToggler
            status={c.value}
            target={c.row.original}
          />
        ),
        filter: (rows, colIds, filterVal) => {
          if (Array.isArray(filterVal) && filterVal.length) {
            return rows.filter(r => filterVal.includes(r.original.status));
          }
          return rows;
        }
      },
      {
        Header: 'Last Matched',
        accessor: 'last_matched_at',
        maxWidth: 110,
        defaultCanSort: true,
        disableGlobalFilter: true,
        Cell: ({value}) => <RelativeTime date={value}/>,
      },
      {
        Header: 'Last Sold',
        accessor: 'last_sold_at',
        maxWidth: 110,
        defaultCanSort: true,
        disableGlobalFilter: true,
        Cell: ({value}) => <RelativeTime date={value}/>,
      },
      {
        Header: 'Created',
        accessor: 'created_at',
        defaultCanSort: true,
        maxWidth: 110,
        disableGlobalFilter: true,
        Cell: ({value}) => <RelativeTime date={value}/>,
      },
      {
        Header: 'Archived',
        accessor: 'archived_at',
        maxWidth: 110,
        defaultCanSort: true,
        disableGlobalFilter: true,
        Cell: ({value}) => <RelativeTime date={value}/>,
      },
      {
        Header: 'Actions',
        id: 'actions',
        Cell: ({row}) => <ActionCell sourceToken={row.original} onArchive={onArchive} onEdit={onEdit}/>,
        canSort: false,
        maxWidth: 60,
        disableGlobalFilter: true,
        className: 'table-cell-actions',
      },
    ]
  }, [])


  const initialTableState = useMemo(() => ({
    sortBy: [{ id: 'vendor', desc: false }],
  }), []);


  return (
    <div className="table-container">

      <PagedTable
        columns={columns}
        data={data}
        initialState={initialTableState}
        className='sources-tokens-table striped'
        loading={loading}
        {...tableProps}
        noDataMessage={noDataMessage}
      />
    </div>

  )
}



const ActionCell = ({sourceToken, onEdit, onArchive}) => {

  return (
    <div className='list-actions'>
      <Button
        color='link'
        className='action-item inline'
        title='Edit'
        onClick={() => onEdit(sourceToken) }
      >
        <FaIcon icon='cog' size='1x' />
      </Button>
      {sourceToken.status !== 'archived' && (
        <Button
          color='link'
          className='action-item inline'
          title='Archive'
          onClick={() => onArchive(sourceToken)}
        >
          <FaIcon icon='archive' size='1x' />
        </Button>
      )}
    </div>
  )
}

const TokenCell = ({tokenId, label, description, token}) => {
  return (
    <>
      <div>{label || token}</div>

        {description && <small>{description}</small>}
       <small>

         <div className="mt-1 d-flex flex-nowrap align-items-center" style={{borderTop:'1px solid #dee2e6'}}>

           <div className="d-flex flex-nowrap align-items-center">
             {`...${token.substring(token.length - 8)}`}
             <CopyButton
               id={`copy-token-${tokenId}`}
               textToCopy={token}
               buttonSize="xs"
               title="Copy token"
               className="m-0  p-0"
             >
               <FaIcon icon="copy" size="sm"/>
             </CopyButton>
           </div>

           <div className="me-1">|</div>
           <div className="d-flex flex-nowrap align-items-center me-1">
             <Button tag={Link} color="link" to={`/publishers/${token}`} size="xs" target="_blank" className="inline" id={`link-publisher-${token}`}>
               Instructions
             </Button>
             <CopyButton
               id={`copy-publisher-link-${tokenId}`}
               textToCopy={`${location.protocol}//${location.host}/#/publishers/${token}`}
               buttonSize="xs"
               title="Copy link to instructions"
               className="m-0 p-0"
             >
               <FaIcon icon="copy" size="sm"/>
             </CopyButton>
             <UncontrolledTooltip target={`link-publisher-${token}`}>
               View publisher instructions (public link)
             </UncontrolledTooltip>
           </div>

         </div>
       </small>


    </>
  )}

