import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import useFormState from "../../../Hooks/useFormState";
import useApi from "../../../Hooks/useApi";
import {Row, Col, Button, Input,  FormGroup, Label, FormFeedback,Spinner} from 'reactstrap';
import {notify, StandardAlert} from "@thedmsgroup/mastodon-ui-components";
import PageSection from "../../../Layout/PageSection";
import {BlockUI} from "ns-react-block-ui";
import DocTitle from "../../../Layout/DocTitle";
import * as Yup from "yup";
import EnhancedSelect from "../../../Components/Form/EnhancedSelect";
import {SourceOptionLabel} from "../../../Components/Form/SourceOptionLabel";

const ValidationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required('Please enter a source name'),
  //originally we were allowing blank for the numbers
  //timeframe:Yup.number().transform((val, orig) => orig == "" ? undefined : val).min(1, 'Enter a positive number for timeframe, or leave blank').nullable(),
 // shared_sales_limit:Yup.number().transform((val, orig) => orig == "" ? undefined : val).min(1, 'Enter a positive number for sales limit, or leave blank').nullable(),

  timeframe:Yup.number().transform((val, orig) => orig == "" ? undefined : val).min(1, 'Enter a positive number for timeframe, or leave blank').required('Timeframe is required'),
  shared_sales_limit:Yup.number().transform((val, orig) => orig == "" ? undefined : val).min(1, 'Enter a positive number for sales limit, or leave blank').required('Sales Limit is required'),

});



export const SaleGroupForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(true);

  const {api} = useApi();
  const [sourceChoices, setSourceChoices] = useState([])
  const {
    formApi, formIsValid, formValues, formErrors,
  } = useFormState(
    {
      shared_sales_limit:0,
      timeframe:0,
      sources:[]

    },
    ValidationSchema,
    undefined,
    undefined,
    { id:params.id }, // validationContext allows conditional validation
  );


  //Reset form on unmount
  useEffect(() => {
    loadChoices();
    loadSaleGroup();
    return () => formApi.clearForm();
  }, [])

  const loadChoices = async() => {
    const sources = await api.endpoints.sources.list({options:true})
    if (sources) {
      //we need to know source vertical id for the seed data builder
      setSourceChoices(sources.map((source:any )=> {
        return {
          value:source.id,
          label:source.name,
          status:source.status,
          vertical:source.vertical,
          product:source.product
        }
      }));
    }
  }

  const loadSaleGroup = async () => {
    if (params.id) {
      const result = await api.endpoints.saleGroups.show(params.id);
      if (result) {

        formApi.setValues( {
          name: result.name,
          shared_sales_limit: result.shared_sales_limit ?? 0,
          timeframe:result.timeframe ?? 0,
          sources: result.sources ? result.sources.map((s:any) => s.id.toString()) : []
        })
      } else {
        notify(`Unabled to find the sale group`, 'error');
      }
    }
    setLoading(false);
  }

  const handleSelectSources = (sources:any[]) => formApi.setValue('sources', sources);

  const handleSave = () => {
    const isValid = formApi.validate();
    if (isValid) {
      save();
    }
  }

  const save = async() => {
    setLoading(true);
    const values = {...formValues};
    if (!values.shared_sales_limit) {
      values.shared_sales_limit = 0;
    }

    if (!values.timeframe) {
      values.timeframe = 0;
    }

    let result;
    if (params.id) {
      result = await api.endpoints.saleGroups.update({id:params.id, ...values});
    } else {
      result = await api.endpoints.saleGroups.create(values);
    }

    setLoading(false);
    if (result) {
      notify(`The sale group has been saved`, 'success');
      goBack();

    } else {
      notify(`Unable to save the sale group: ${api.error ? api.error.name : ""}`, 'error');
    }
  }

  //TODO: this could be a hook
  const goBack = () => {
    if (location.state && location.state.return) {
      if (location.state.return.indexOf('#') === 0) {
        //hash will be like '#/my-list', need to remove has symbol
        navigate(location.state.return.substring(1))
      } else {
        navigate(location.state.return);
      }
    } else {
      navigate('..', { relative: "path" }) //up the route hierarchy
    }
  }

  return (
    <PageSection title={`${params.id ? 'Edit' : 'Create'} Sale Group`}>
      <DocTitle pageTitle={`${params.id ? 'Edit' : 'Create'} Sale Group`}/>

        <Row>

          <Col sm={12} md={6} >
            <BlockUI
              blocking={loading}
              mode="stretch"
              loader={<Spinner color="success" />}
            >

            {!formIsValid
              && (
                <StandardAlert color="warning" icon="alert" className="alert-form-validation">
                  Correct the errors indicated below
                </StandardAlert>
              )}

            <FormGroup>
              <Label>
                Name
              </Label>
              <Input
                type="text"
                name="name"
                onChange={formApi.handleOnChange}
                value={formValues.name || ''}
                invalid={!!formErrors.name}
              />
              <FormFeedback>{formErrors.name}</FormFeedback>
            </FormGroup>

            <FormGroup>
              <Label>Sources</Label>

              <EnhancedSelect
                value={formValues.sources}
                options={sourceChoices}
                controlShouldRenderValue
                isMulti
                isSearchable
                onChange={handleSelectSources}
                formatOptionLabel={SourceOptionLabel}
                isInvalid={!!formErrors.sources}
                invalidFeedback={formErrors.sources}
              />
            </FormGroup>

            <FormGroup>
              <Label>
                Shared Sales Limit
              </Label>
              <Input
                type="number"
                min={0}
                name="shared_sales_limit"
                onChange={formApi.handleOnChange}
                value={formValues.shared_sales_limit || ''}
                className="teeny"
                invalid={!!formErrors.shared_sales_limit}
              />
              <FormFeedback>{formErrors.shared_sales_limit}</FormFeedback>
            </FormGroup>

            <FormGroup>
              <Label>
                Timeframe (days)
              </Label>
              <Input
                type="number"
                min={0}
                name="timeframe"
                onChange={formApi.handleOnChange}
                value={formValues.timeframe || ''}
                className="teeny"
                invalid={!!formErrors.timeframe}
              />
              <FormFeedback>{formErrors.timeframe}</FormFeedback>
            </FormGroup>

            <div className="mt-2 text-end">
              <Button
                size="sm"
                onClick={goBack}
                color="link"
                className="me-1"
                disabled={loading}
              >
                Cancel
              </Button>
              <Button
                onClick={handleSave}
                disabled={loading}
              >
                Save
              </Button>
            </div>
            </BlockUI>
          </Col>

        </Row>

    </PageSection>
  );
}
