import React, {useContext} from 'react';
import { Routes, Route } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import OrderList from './OrderList';
import AccountSettings from './Account/Settings';
import RulesManager from './RulesManager';
import NotAuthorized from '../../Layout/NotAuthorized';
import usePermission from '../../Hooks/usePermission';
import ProtectedRoute from '../../Components/ProtectedRoute';
import './styles.scss';
import {AppContext} from "../../Providers/AppProvider";

/*
 * Entry point to all advertiser routes
 */
const AdvertiserManager = () => {
  const app = useContext(AppContext)

  const allowAccountsView = usePermission('accounts.view');
  const allowChannelsView = usePermission('channels.view');
  const allowPublishersView = false; //hide MRK-10881  app.vendor_id !== null;

  if (!allowAccountsView) {
    if (allowChannelsView) {
      return <Navigate to="/publishing/channels" />;
    }

    if (allowPublishersView) {
      return <Navigate to="/dashboard/publishers" />;
    }

    // permissions gave them nothing to view?
    return <Routes><Route element={NotAuthorized} /></Routes>;
  }


  // Paths relative to /accounts
  return (
    <Routes>
      <Route index element={<OrderList />} />

      <Route path="/:accountId/order/:orderId/rules" element={<RulesManager />} />

      <Route path="/:accountId/settings" element={<AccountSettings />} />

      <Route path="create" element={<ProtectedRoute permission="accounts.create" /> } >
        <Route index element={<AccountSettings />} />
      </Route>

    </Routes>
  );
};

export default AdvertiserManager;
