import React from 'react';
import {Link, Outlet} from 'react-router-dom';
import Footer from '../Layout/Footer';
import {Navbar, NavbarBrand} from "reactstrap";
import logoImage from "../images/DMS_logo_mastodon_white.svg";
//import logoImage from '../images/DMS_logo_mastodon_white.svg';
import './main_content.scss';

/*
 * Template wrapper for non-authenticated pages like Login, reset password.
 */
export const PublicLayout = () => (
  <>
    <div id="public-layout">

      <header className="header-main">
        <Navbar className="main-navbar" expand="md" fixed="top">

            <NavbarBrand tag={Link} to="/login">
              <img src={logoImage} width="160" alt="Mastodon: powered by DMS" />
            </NavbarBrand>

        </Navbar>
      </header>

      <div id="public-content">

        <Outlet />

      </div>

      <div className="push-footer" />

    </div>
    <Footer />
  </>

);

