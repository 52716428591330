import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import DocTitle from "../../Layout/DocTitle";
import useApi from "../../Hooks/useApi";
import PostingInstructions from "../Dashboard/Publishers/PostingInstructions";

const PublisherPostDocs = () => {
  const {api} = useApi();
  const {sourceToken} = useParams();
  const [apiKey, setApiKey] = useState('');
  const [sources, setSources] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const getPostingInstructions = async () => {
    try {
      const postingInstructions = await api.client.get(`/public/publishers/instructions/${sourceToken}`);
      const {api_key, sources} = postingInstructions;
      //Hack: rewriting placeholder provided by API. THere is a logged-in dashboard version of these docs,
      //but for now we are hiding it, we don't want them logged in.
      setApiKey(api_key === "<Login to see>" ? "<Provided by DMS>" : api_key);
      setSources(sources);
    } catch (exception) {
      console.error('Error fetching Publisher Posting Instructions:', exception)
    } finally {
      setIsLoading(false);
    }

  }

  useEffect(() => {
    getPostingInstructions()
  }, [sourceToken])

  return (
    <>
      <DocTitle pageTitle="Publisher Documentation"/>
       <PostingInstructions apiKey={apiKey} sources={sources} isLoading={isLoading}/>
    </>
  );
};

export default PublisherPostDocs;
