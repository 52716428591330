import React, { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import FlexTable from "../../../Components/Table/FlexTable";
import { RelativeTime, Stack, RowDivider } from '@thedmsgroup/mastodon-ui-components';
import {AnyCell} from "../../../Components/Table/Table";
import EntityId from "../../../Components/Table/EntityId";
import {SaleGroupListing, TableProps} from "../../../types";
import {FontAwesomeIcon as FaIcon} from "@fortawesome/react-fontawesome";
import usePermission from "../../../Hooks/usePermission";

type SaleGroupListTableProps = TableProps & {
  data:SaleGroupListing[];
}

export const SaleGroupTable:FC<SaleGroupListTableProps> = ({data, loading}) => {
  const allowEdit = usePermission('sources.edit');
  const columns = useMemo(() => [
    {
      Header: 'ID',
      accessor: 'id',
      Cell: ({value}: {value:number}) => <><EntityId id={value} /></>,
      maxWidth: 60
    },
    {
      Header: 'Sale Group',
      accessor: 'name',
    },
    {
      Header: 'Sources',
      accessor: 'sources',
      Cell:({value}:{value:SaleGroupListing[]}) => <SourcesCell sources={value} />,
      minWidth:250,
    },

    {
      Header: 'Sales Limit',
      accessor: 'shared_sales_limit',
    },
    {
      Header: 'Time Frame (days)',
      accessor: 'timeframe',
    },
    {
      Header: 'Created',
      accessor: 'created_at',
      Cell: ({ value }:any) => {
        return <RelativeTime date={value} showTimezone={true} />;
      },
    },
    {
      Header: 'Updated',
      accessor: 'updated_at',
      Cell: ({ value }:any) => {
        return <RelativeTime date={value} showTimezone={true} />;
      },
    },
    {
      Header: 'Actions',
      id: 'actions',
      Cell: ({row}:{row:any}) => {
        return <ActionCell saleGroupId={row.original.id} allowEdit={allowEdit} />
      },
      maxWidth:80
    },
  ], []);

  return (
    <FlexTable
      data={data}
      columns={columns}
      initialState={{
        sortBy: [{id: 'name', desc: false}]
      }}
      className="sale-group-table striped"
      noDataMessage="No rows found"
      loading={loading}
      />
  )
}

const ActionCell = ({saleGroupId, allowEdit}:{saleGroupId:number, allowEdit:boolean}) => {

  return (
    <div className="list-actions">
      {allowEdit && <Link
        to={saleGroupId.toString()}
        state={{"return":window.location.hash}}
        title='Edit'
        className='action-item'
      >
        <FaIcon icon='cog' size='1x' />
      </Link>}
    </div>
  )
}

const SourcesCell = ({sources}:{sources:any[]}) => {

  return (
    <>
      {sources.length > 0 ? (
        <Stack gap="4px" divider={<RowDivider/>}>
          {sources.map((s:any, i:number) => (
            <div key={i}><EntityId id={s.id} /> {s.name}</div>
          ))}
        </Stack>
      ) : (
        <AnyCell />
      )}
    </>
  )
}
