import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ChannelList from './ChannelList';
import SourceList from './SourceList';
import VendorsList from './VendorsList';
import PhoneNumberList from './PhoneNumberList';
import SourceTokenList from "./SourceToken/List";
import {SaleGroupList} from "./SaleGroup";
import {SaleGroupForm} from "./SaleGroup/Edit";
import ProtectedRoute from '../../Components/ProtectedRoute';
import './styles.scss';

/*
 * Entry point to all publishing routes
 */
const PublisherManager = () => (

  <Routes>

    <Route path="channels" element={<ProtectedRoute permission="channels.view" /> } >
      <Route index element={<ChannelList />} />
    </Route>

    <Route path="sources" element={<ProtectedRoute permission="sources.view" /> } >
      <Route index element={<SourceList />} />
    </Route>

    <Route path="sources/phone-numbers" element={<ProtectedRoute permission="phone_numbers.view" /> } >
      <Route index element={<PhoneNumberList />} />
    </Route>

    <Route path="vendors" element={<ProtectedRoute permission="vendors.view" /> } >
      <Route index element={<VendorsList />} />
    </Route>

    <Route path="source-tokens" element={<ProtectedRoute permission="vendors.edit" /> } >
      <Route index element={<SourceTokenList />} />
    </Route>

    <Route path="sale-groups" element={<ProtectedRoute permission="sources.view" /> } >
      <Route index element={<SaleGroupList />} />
      <Route path=":id" element={<SaleGroupForm />} />
      <Route path="create" element={<SaleGroupForm />} />
    </Route>

  </Routes>

);

PublisherManager.displayName = 'PublisherMananger';
export default PublisherManager;
