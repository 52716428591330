import React from 'react';
import {
  Row, Col,
  FormGroup, Label, FormText, Input, InputGroup, FormFeedback,
} from 'reactstrap';
import NumberFormat from 'react-number-format';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';
import { Required } from '../../../Components/Form/FormCommon';

import { titleCaseWord } from '../../../utils/string';
import DynamicSingleSelect from '../../../Components/Form/DynamicSingleSelect';
import InputWithIcon from '../../../Components/Form/InputWithIcon';

// Mask dd.dd
const currencyMask = createNumberMask({
  prefix: '',
  allowDecimal: true,
  decimalLimit: 2,
  integerLimit: 3,
  // fixedDecimalScale:true
});

// Mask ddd
const marginMask = createNumberMask({
  prefix: '',
  allowDecimal: false,
  integerLimit: 3,
});

const Details = ({
  source, errors, onChange, isNew,
}) => {
  const phoneCount = source.phone_numbers ? source.phone_numbers.length : 0;

  return (
    <Row>
      <Col sm={6}>
        <FormGroup>
          <Label>
            Name
            {' '}
            <Required />
          </Label>
          <Input
            type="text"
            name="name"
            onChange={onChange}
            value={source.name || ''}
            invalid={!!errors.name}
          />
          <FormFeedback>{errors.name}</FormFeedback>
        </FormGroup>

        <FormGroup>
          <Label>
            Description
          </Label>
          <Input
            type="textarea"
            name="description"
            onChange={onChange}
            value={source.description || ''}
          />

        </FormGroup>

        <FormGroup>
          <Label>
            Status
          </Label>
          <InputGroup>
            <Input type="select" onChange={onChange} value={source.status} name="status">
              <option value="active">Active</option>
              <option value="paused">Paused</option>
              <option value="archived">Archived</option>
            </Input>
          </InputGroup>
        </FormGroup>

        <div className="d-flex">

          <FormGroup>
            <Label>
              Vendor Bid
            </Label>

            <MaskedInput
              id="inp-bid"
              mask={currencyMask}
              name="vendor_bid"
              value={source.vendor_bid || ''}
              onChange={onChange}
              invalid={false}
              error={null}
              render={(ref, props) => <InputWithIcon className="tiny" innerRef={ref} icon="dollar-sign" {...props} />}
            />
            <FormText>Bid, if set, will override margin</FormText>
          </FormGroup>

          <FormGroup className="ms-4">
            <Label>
              Vendor Margin
            </Label>
            <MaskedInput
              id="inp-margin"
              mask={marginMask}
              name="vendor_margin"
              value={source.vendor_margin || ''}
              onChange={onChange}
              invalid={false}
              error={null}
              render={(ref, props) => <InputWithIcon className="tiny" innerRef={ref} icon="percent" iconSide="right" {...props} />}
            />
          </FormGroup>

        </div>

        <FormGroup>
          <Label>
            Match Limit
          </Label>
          <Input
            className="tiny"
            name="match_limit"
            value={source.match_limit || ''}
            onChange={onChange}
            invalid={false}
            error={null}
          />
          <FormText>Limits the number of matches returned</FormText>
        </FormGroup>

        <FormGroup>
          <Label>
            Auction Expiration (in minutes)
          </Label>
          <Input
            className="tiny"
            name="auction_expiration"
            value={source.auction_expiration || ''}
            onChange={onChange}
            invalid={false}
            error={null}
          />
          <FormText>By default leads and calls auctions expire after 10 minutes, but some sources might require extended timeouts</FormText>
        </FormGroup>

        { source.product === 'calls' && (
          <FormGroup>
            <Label>
              Vendor Billable Duration (seconds)
            </Label>
            <Input
              type="number"
              name="vendor_billable_duration"
              max={500}
              onChange={onChange}
              className="tiny"
              value={source.vendor_billable_duration || ''}
              invalid={!!errors.vendor_billable_duration}
            />
            <FormFeedback>{errors.vendor_billable_duration}</FormFeedback>
            <FormText>Maximum 500 seconds</FormText>
          </FormGroup>
        )}

      </Col>

      <Col sm={6}>

        { source.product === 'calls' && (
          <FormGroup>
            <Label for="phone_number">
              Phone Numbers
              {phoneCount > 0 && (
              <span>
                (
                {phoneCount}
                )
              </span>
              )}
            </Label>
            {phoneCount > 0 ? (
              <ul className="phone-number-list">
                {source.phone_numbers.map((phone, i) => <li key={i}><NumberFormat value={phone.phone_number} displayType="text" format="+###########" /></li>)}
              </ul>
            ) : <div><i>No phone numbers assigned</i></div>}

          </FormGroup>
        )}



        <FormGroup>

          <Label>
            Product
            {' '}
            { isNew && <Required />}
          </Label>
          { isNew && (

            <InputGroup>
              <Input
                type="select"
                onChange={onChange}
                defaultValue={source.product}
                name="product"
                invalid={!!errors.product}
              >
                <option value="">Select...</option>
                <option value="clicks">Clicks</option>
                <option value="calls">Calls</option>
                <option value="leads">Leads</option>
              </Input>
            </InputGroup>

          )}

          {/* Read only text for existing source */}
          { !isNew && source.product && (
            <Input type="text" defaultValue={titleCaseWord(source.product)} readOnly />
          )}
          <FormFeedback>{errors.product}</FormFeedback>
        </FormGroup>

        <FormGroup>

          <Label>
            Vertical
            {' '}
            { isNew && <Required />}
          </Label>
          { isNew && (

            <DynamicSingleSelect
              onChange={onChange}
              defaultValue={source.vertical_id}
              endpoint="verticals"
              name="vertical_id"
              invalid={!!errors.vertical_id}
              labelProperty="display_name"
              params={{options:true}}
            />

          )}

          {/* Read only text for existing source */}
          { !isNew && source.vertical && (
            <Input type="text" defaultValue={source.vertical.display_name || ''} readOnly />
          )}
          <FormFeedback>{errors.vertical_id}</FormFeedback>
        </FormGroup>



      </Col>
    </Row>
  );
};

Details.propTypes = {
  source: PropTypes.object,
  errors: PropTypes.object,
  onChange: PropTypes.func,
  isNew: PropTypes.bool,
};

export default Details;
