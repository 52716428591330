import React from 'react';
import PropTypes from 'prop-types';

/*
 FilterSummary: Produce text summary of selected filters.
 Designed for use in the "Applied Filters" dropdown
 */

const FilterLabelMap = {
  account: 'ACCOUNT',
  accounts: 'ACCOUNT',
  billable: 'BILLABLE',
  channel: 'CHANNEL',
  change_action: 'CHANGE ACTION',
  change_group: 'CHANGE TYPE',
  currentlyInsured: 'INSURED',
  device: 'DEVICE',
  disposition: 'DISPOSITION',
  entity_type: 'OBJECT',
  order: 'ORDER',
  orders: 'ORDER',
  phase: 'PHASE',
  product: 'PRODUCT',
  script: 'SCRIPT',
  sold: 'SOLD',
  source: 'SOURCE',
  sources: 'SOURCE',
  state: 'STATE',
  status: 'STATUS',
  tag: 'TAG',
  vendor: 'VENDOR',
  vendors: 'VENDOR',
  vertical: 'VERTICAL',
  verticals: 'VERTICAL',
  integration: 'INTEGRATION',
  integrations: 'INTEGRATIONS',
  'vertical.name': 'VERTICAL',
  'include_buyers': 'INCLUDE_BUYERS',
};

// Map of filter keys to choices keys
const FilterChoiceMap = {
  'vertical.name': 'vertical',
  vertical: 'verticals',
  channel_id: 'channel',
  vertical_id: 'vertical',
  vendor_id: 'vendor',
  source_id: 'source',
  order_id: 'order',
};

const FilterSummary = ({ filters, filterKeys = [], choices }) => {
  // TBD:Limit length of each filter. Limit total length.
  filters = keepKeys(filters, filterKeys)
  if (Object.keys(filters).length === 0) {
    return (<i>No filters applied. Click to add filters.</i>);
  }

  // keys in selections are going to be 'params'
  const summary = summarizeFilterSettings(filters, choices);

  if (!Object.keys(summary).length) return (null);

  return (
    <span>
      {Object.entries(summary).map(([filterName, val], i) => {
        if (Array.isArray(val) && val.length > 0) {
          return (
            <span key={i}>
              <strong>{FilterLabelMap[filterName]}:</strong> {val.join(',')}
            </span>
          );
        } else {
          return (
            <span key={i}>
              <strong>{FilterLabelMap[filterName]}:</strong> {val}
            </span>
          )
        }
      }).reduce((prev, curr) => [prev, ', ', curr])}
    </span>
  );
};

const keepKeys = (obj, keys) => {
  obj = { ... obj }
  Object.keys(obj).map(key => {
    if (!keys.includes(key)) {
      delete obj[key]
    }
  })
  return obj
}

FilterSummary.propTypes = {
  filters: PropTypes.object,
  filterKeys: PropTypes.array,
  choices: PropTypes.object,
};

export default FilterSummary;

const summarizeFilterSettings = (filters, choices) => {
   // console.log('FilterSummary.js: summarizeFilterSettings', filters, choices);
  const filterSummary = {};
  Object.entries(filters).map(([key, val]) => {
    // console.log('FilterSummary.js: summarizeFilterSettings:', key, val);
    // Map: param could be vertical.name, but choices is 'vertical'
    key = FilterChoiceMap[key] || key;
    if (choices.hasOwnProperty(key)) {
      const labels = getLabels(choices[key], val);
      if (labels) {
        filterSummary[key] = labels;
      }
    } else {
      // todo guard against non-strings
      filterSummary[key] = val;
    }
  });
  return filterSummary;
};

const getLabels = (options, values) => {
   //console.log('ReportsFilter.js:summarize getLabels', options, values);
  if (values && Array.isArray(options)) {
    // options.filter((opt) => values.includes(opt.value))
    const selected = Array.isArray(values)
      ? options.filter((opt) => values.some(val => val.toString() === opt.value.toString()))
      : options.filter((opt) => opt.value.toString() === values.toString());

    const labels = selected.length ? selected.map((opt) => opt.label) : null;

    // console.log('FilterSummary.js:summarize, getLabels: selected, labels:', selected, labels);
    return labels;
  }
  return null;
};
