import React, { useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import { notify } from '@thedmsgroup/mastodon-ui-components/lib/common/Notify';
import useApi from "../../../Hooks/useApi";
import { Button} from 'reactstrap';
import type {SaleGroupListing} from "../../../types";
import {SaleGroupTable} from "./Table";
import usePermission from "../../../Hooks/usePermission";
import PageSection from "../../../Layout/PageSection";
import DocTitle from "../../../Layout/DocTitle";

export const SaleGroupList = () => {
  const {api} = useApi();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [saleGroups, setSaleGroups] = useState<SaleGroupListing[]>([])
  const allowEdit = usePermission('sources.edit');

  useEffect(() => {
    load();
  }, [])

  const load = async() => {
    setLoading(true);
    const result = await api.endpoints.saleGroups.list();
    if (result) {
      setSaleGroups(result as SaleGroupListing[]);
    } else {
      notify("Unable to load Sale Group data", "error");
    }
    setLoading(false);
  }

  const onAdd = () => {
    navigate("create", {state:{foo:99}})
  }

  return (
    <>
      <DocTitle pageTitle="Sale Groups" />

      <PageSection
        title="Sale Groups"
        tools={allowEdit ? (<Button
          size="sm"
          onClick={onAdd}
        >
          Add
        </Button>) : null}
      >
        <SaleGroupTable
          loading={loading}
          data={saleGroups}
        />
      </PageSection>
    </>
  );
}
