import React, { useState, useEffect, useMemo } from 'react';
import {
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col, FormText,
} from 'reactstrap';
import PropTypes from 'prop-types';
import copy from 'fast-copy';
import IconCheckbox from "../../Form/IconCheckbox";
import { useDebouncedCallback } from 'use-debounce';
import ConfigSettingsForm from './ConfigSettingsForm';
import EnhancedSelect from "../../Form/EnhancedSelect";
import {FontAwesomeIcon as FaIcon} from "@fortawesome/react-fontawesome";
import StatusToggler from "../../Form/StatusToggler";
import {Utils} from "@thedmsgroup/mastodon-ui-components";

const ConfigEdit = (props) => {
  // The rule loads all possible integration configurations for the vertical/product, so
  // here we select the config by name
  const getDefinition = (name) => props.definitions.find((conf) => conf.name === name);
  const [originalData, setOriginalData] = useState(props.config);
  const [selectedDefinition, setSelectedDefinition] = useState(() => {
    // set selected to existing config, or default if only 1 config exists, else no defined selection
    if (props.config.name) {
      return getDefinition(props.config.name);
    }
    return props.definitions.length === 1 ? props.definitions[0] : undefined;
  });

  // If using service chooser
  // Handle changing the integration service, which changes the available settings
  const handleChangeService = (name) => {
    const def = getDefinition(name);
    setSelectedDefinition(def);

    if (!def) {
      props.onUpdate({ name: '', settings: '' }, props.index);
    } else if (originalData && originalData.name === name) {
      props.onUpdate(copy(originalData), props.index);
    } else {
      const newSettings = {};
      if (def.supported_settings) {
        def.supported_settings.map((s) => newSettings[s.name] = '');
      }

      const newConfig = { name, settings: newSettings };
      props.onUpdate(newConfig, props.index);
    }
  };

  const handleChangeTimeout = useDebouncedCallback((val) => {
    let newConfig = copy(props.config)
    newConfig.timeout = val
    props.onUpdate(newConfig, props.index)
  }, 400)

  const handleExcludePii = (name, val) => {
    let newConfig = copy(props.config)
    newConfig.exclude_pii = val
    props.onUpdate(newConfig, props.index)
  }

  // on enter edit mode, update parent if config already selected
  useEffect(() => {
    if (selectedDefinition) {
      handleChangeService(selectedDefinition.name);
    }
  }, []);

  const handleApply = () => {
    setOriginalData(props.config);
    props.onApply();
  };

  const handleCancel = () => {
    if (props.isNew) {
      props.onDelete(props.index);
    } else {
      if (originalData) {
        props.onUpdate(copy(originalData), props.index);
        setSelectedDefinition(getDefinition(originalData.name));
      }
      props.onApply();
    }
  };

  return (
    <Form>
      {props.showServiceChooser && (
        <ServiceSelect
          definitions={props.definitions}
          value={selectedDefinition ? selectedDefinition.name : ''}
          onChange={handleChangeService}
          hasSettings={selectedDefinition && selectedDefinition.supported_settings?.length > 0}
          error={props.errors['integration.name']}
        />
      )}


      {selectedDefinition !== undefined && (
        <>
          {Array.isArray(selectedDefinition.supported_settings) && selectedDefinition.supported_settings.length > 0 && (
            <ConfigSettingsForm
              index={props.index}
              definition={selectedDefinition}
              config={props.config}
              onUpdate={props.onUpdate}
              errors={{}}
            />
          )}

          <FormGroup row>
            <Label sm={3} for="settings">Custom Timeout</Label>
            <Col sm={9}>
              <Input
                type="number"
                name="timeout"
                value={props.config.timeout}
                onChange={(e) => handleChangeTimeout(e.target.value)}
              />
              <FormText>In Seconds. Integrations use a sensible default timeout. Only override this carefully!</FormText>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={3} for="exclude_pii">Exclude PII</Label>
            <Col sm={9}>

              <IconCheckbox
                name="exclude_pii"
                value={Boolean(props.config.exclude_pii)}
                onChange={handleExcludePii}
              >
                Exclude PII
              </IconCheckbox>
              <FormText>By checking this box, PII such as phone and email are unavailable to this integration.</FormText>
            </Col>
          </FormGroup>

        </>
      )}


      {props.allowControls && (
        <div className="d-flex justify-content-end">
          {props.isNew === true ? (
            <>
              <Button className="mt=2 me-2" size="sm" color="link" onClick={handleCancel} >Cancel</Button>
              <Button className="mt=2 me-2" size="sm" onClick={handleApply} disabled={!selectedDefinition}>Add</Button>
            </>
          ) : (
            <>
              {/*  disabled={!props.config.name} */}
              <Button className="mt=2 me-2" size="sm" color="link" onClick={handleCancel} >Cancel</Button>
              <Button className="mt=2 me-2" size="sm" onClick={handleApply} disabled={!selectedDefinition}>Apply</Button>
            </>
          )}
        </div>
      )}

    </Form>
  );
};

/*
 * Dropdown for selecting a service (such as for integration), which
 * will change the available settings
 */
const ServiceSelect = ({
                         definitions, value, hasSettings, onChange, error,
                       }) => {
  if (definitions.length === 0) {
    return (<span>No integrations are available.</span>);
  }

  const options = useMemo(() => {
    return definitions.map( def => {
      return {
        value:def.name,
        label:def.label,
        type: def.type,
        status:def.status,
        product:def.product ? Utils.titleCaseWord(def.product) : '',
        vertical:def.vertical?.name,
        account: def.account
      }
    })
  }, [definitions])

  return (
    <FormGroup row>
      <Label sm={3} for="integration">Service</Label>
      <Col sm={9}>

        <EnhancedSelect
          value={value}
          options={options}
          controlShouldRenderValue
          isMulti={false}
          isSearchable
          onChange={onChange}
          formatOptionLabel={IntegrationOptionLabel}
          isInvalid={!!error}
          invalidFeedback={error}
          //Archived options shown for BC purposes but won't validate
          isOptionDisabled={(option) => option.status === 'archived'}
        />
        {value && hasSettings === false && <FormText>This service has no settings</FormText>}

      </Col>
    </FormGroup>
  );
};


const IntegrationOptionLabel = (option, meta, selected ) => {

  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="d-flex flex-fill">
          {meta.context === 'menu' && selected && <div><FaIcon icon="check" size="sm" className="me-1" color="green"/></div>}
          <div>{option.label}</div>
        </div>
        <StatusToggler status={option.status} allowEdit={false} className='me-1'/>
      </div>

      {meta.context === 'menu' && (
        <div>
          <small className="text-muted">
            {option.value}
            {option.product && <span> | {option.product}</span>}
            {option.vertical && <span> | {option.vertical}</span>}
            {option.account && <span> | {option.account.name}</span>}
          </small>
        </div>
      )}
    </>
  )};

ConfigEdit.propTypes = {
  index: PropTypes.number,
  type: PropTypes.string,
  allowControls: PropTypes.bool,
  isNew: PropTypes.bool,
  showServiceChooser: PropTypes.bool,
  definitions: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired, // definitions for all available configurations
  config: PropTypes.object, // current setting values
  onUpdate: PropTypes.func.isRequired,
  onApply: PropTypes.func,
  onDelete: PropTypes.func,
  errors: PropTypes.object,
};

export default ConfigEdit;
